<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="schnittstellen"
      sort-by="richtung"
      class="elevation-1"
      dense
    >
      <template #item.richtung="{item}">
        {{ richtungText(item.richtung) }}
      </template>
      <template #item.kommunikationsArt="{item}">
        {{ kommunikationsArtText(item.kommunikationsArt) }}
      </template>
      <template #item.protokoll="{item}">
        {{ protokollText(item.protokoll) }}
      </template>
      <template #item.format="{item}">
        {{ datenFormatText(item.format) }}
      </template>
      <template #item.haeufigkeit="{item}">
        {{ haeufigkeitText(item.haeufigkeit) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <help />
          <v-toolbar-title>Schnittstellen</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Hinzufügen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <p v-html="formDescription" class="subtitle-2 text-left"></p>
                <v-divider class="mx-1"></v-divider>
                <v-container>
                  <v-form ref="form_schnittstellen" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          :items="systeme"
                          v-model="systeme"
                          item-text="bezeichnung"
                          item-value="id"
                          label="Quelle / Ziel"
                          v-if="!isEditModus"
                          :rules="rules.quelle_ziel"
                        ></v-autocomplete>
                        <v-text-field
                          :value="editedItem.partnerSystem.bezeichnung"
                          label="Quelle / Ziel"
                          disabled
                          v-if="isEditModus"
                          :rules="rules.quelle_ziel"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="richtungen"
                          v-model="editedItem.richtung"
                          label="Richtung"
                          item-text="text"
                          item-value="id"
                          v-if="!isEditModus"
                          :rules="rules.richtung"
                        ></v-select>
                        <v-text-field
                          :value="richtungText(editedItem.richtung)"
                          label="Richtung"
                          disabled
                          v-if="isEditModus"
                          :rules="rules.richtung"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="protokolle"
                          v-model="editedItem.protokoll"
                          label="Protokoll"
                          item-text="bezeichnung"
                          item-value="id"
                          v-if="editedItem.richtung != 1 || !isEditModus"
                          return-object
                          :rules="rules.protokoll"
                        ></v-select>
                        <v-text-field
                          :value="protokollText(editedItem.protokoll)"
                          label="Protokoll"
                          disabled
                          v-if="editedItem.richtung != 0 && isEditModus"
                          :rules="rules.protokoll"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="datenFormate"
                          v-model="editedItem.format"
                          label="Format"
                          item-text="bezeichnung"
                          item-value="id"
                          v-if="editedItem.format || !isEditModus"
                          return-object
                          :rules="rules.format"
                        ></v-select>
                        <v-text-field
                          :value="datenFormatText(editedItem.format)"
                          label="Format"
                          disabled
                          v-if="!editedItem.format && isEditModus"
                          :rules="rules.format"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="arten"
                          v-model="editedItem.kommunikationsArt"
                          label="Art"
                          item-text="text"
                          item-value="id"
                          v-if="editedItem.kommunikationsArt || !isEditModus"
                          :rules="rules.kommunikationsArt"
                        ></v-select>
                        <v-text-field
                          :value="
                            kommunikationsArtText(editedItem.kommunikationsArt)
                          "
                          label="Art"
                          disabled
                          v-if="!editedItem.kommunikationsArt && isEditModus"
                          :rules="rules.kommunikationsArt"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="haeufigkeiten"
                          v-model="editedItem.haeufigkeit"
                          label="Häufigkeit"
                          item-text="text"
                          item-value="id"
                          v-if="editedItem.haeufigkeit || !isEditModus"
                          :rules="rules.haeufigkeit"
                        ></v-select>
                        <v-text-field
                          :value="haeufigkeitText(editedItem.haeufigkeit)"
                          label="Häufigkeit"
                          disabled
                          v-if="!editedItem.haeufigkeit && isEditModus"
                          :rules="rules.haeufigkeit"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="12">
                        <v-textarea
                          name="input-7-1"
                          label="Datenbeschreibung"
                          v-model="editedItem.datenBeschreibung"
                          hint="Beschreiben Sie hier die Daten aus fachlicher Sicht."
                          :rules="rules.datenBeschreibung"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  Abbruch
                </v-btn>
                <v-btn color="primary" text @click="save">
                  Übernehmen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h6"
                >Möchten Sie die Schnittstelle wirklich löschen?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Abbruch</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span> {{ item.partnerSystem.bezeichnung }} löschen</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="deleteItem(item)"
              v-if="item.richtung != 1"
              color="error"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span> {{ item.partnerSystem.bezeichnung }} löschen</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-btn
            class="mr-1"
            outlined
            @click="saveSchnittstellen"
            v-if="schnittstellen.length > 0 || form_saved == false"
          >
            speichern
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-btn
            outlined
            @click="initialize"
            v-if="schnittstellen.length > 0 || form_saved == false"
          >
            Zurücksetzen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SystemService from "@/services/old/SystemService.js";
import SchnittstellenService from "@/services/old/SchnittstellenService.js";
import ProtokolleService from "@/services/old/ProtokolleService.js";
import DatenFormateService from "@/services/old/DatenFormateService.js";
import Help from "@/components/old/help/SchnittstellenHelp.vue";

const haeufigkeit_daten = [
  {
    id: "0",
    text: "Täglich"
  },
  {
    id: "1",
    text: "Monatlich"
  },
  {
    id: "2",
    text: "Jährlich"
  },
  {
    id: "3",
    text: "AdHoc"
  }
];

const arten_daten = [
  {
    id: "0",
    text: "syncron"
  },
  {
    id: "1",
    text: "asyncron"
  }
];

const richtung_daten = [
  {
    id: "0",
    text: "eingehend"
  },
  {
    id: "1",
    text: "ausgehend"
  }
];

export default {
  components: { Help },
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Quelle/Ziel",
        align: "start",
        value: "partnerSystem.bezeichnung"
      },
      { text: "Richtung", value: "richtung" },      
      { text: "Protokoll", value: "protokoll" },
      { text: "Format", value: "format" },
      { text: "Art", value: "kommunikationsArt" },
      { text: "Häufigkeit", value: "haeufigkeit" },
      { text: "Datenbeschreibung", value: "datenBeschreibung" },
      { text: "Aktionen", value: "actions", sortable: false }
    ],
    rules: {
      quelle_ziel: [
        v => !!v || "Quelle oder Ziel erforderlich",
        v => v.length > 0 || "Quelle oder Ziel erforderlich"
      ],
      richtung: [v => !!v || "Richtung erforderlich"],
      protokoll: [v => !!v || "Protokoll erforderlich"],
      format: [v => !!v || "Format erforderlich"],
      kommunikationsArt: [v => !!v || "Art erforderlich"],
      haeufigkeit: [v => !!v || "Häufigkeit erforderlich"],
      datenBeschreibung: [v => !!v || "Datenbeschreibung erforderlich"]
    },
    haeufigkeiten: haeufigkeit_daten,
    arten: arten_daten,
    richtungen: richtung_daten,
    partnerSysteme: [],
    schnittstellen: [],
    protokolle: [],
    datenFormate: [],
    editedIndex: -1,
    editedItem: {
      partnerSystem: "",
      richtung: "",
      protokoll: "",
      format: "",
      kommunikationsArt: "",
      haeufigkeit: "",
      datenBeschreibung: ""
    },
    defaultItem: {
      partnerSystem: {},
      richtung: "",
      protokoll: "",
      format: "",
      kommunikationsArt: "",
      haeufigkeit: "",
      datenBeschreibung: ""
    },
    isEditModus: false,
    snackbar: false,
    message: "",
    snackbar_timeout: 2000,
    form_saved: true
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Schnittstelle Hinzufügen"
        : "Schnittstelle Ändern";
    },
    formDescription() {
      return "Spezifizieren Sie die verwendete Schnittstelle.";
    },
    systeme: {
      get() {
        return this.$store.getters.systeme.filter(
          item => item.id != this.$store.getters.currentSystemId
        );
      },
      set(value) {
        this.editedItem.partnerSystem = this.$store.getters.systeme.find(
          function(item) {
            return item.id == value;
          }
        );
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadSchnittstellen();
      this.loadProtokolle();
      this.loadDatenFormate();
    },
    async loadSchnittstellen() {
      let resp = await SchnittstellenService.getSchnittstellen(
        this.$store.getters.currentSystemId
      );
      this.schnittstellen = resp.data;
      if (resp.status == 200) {
        this.message = "Schnittstellen gelesen.";
      } else {
        this.message = resp.data;
      }
    },
    async loadProtokolle() {
      let resp = await ProtokolleService.getProtokolle();
      this.protokolle = resp.data;
      if (resp.status == 200) {
        this.message = "Protokolle gelesen.";
      } else {
        this.message = resp.data;
      }
    },
    async loadDatenFormate() {
      let resp = await DatenFormateService.getDatenFormate();
      this.datenFormate = resp.data;
      if (resp.status == 200) {
        this.message = "DatenFormate gelesen.";
      } else {
        this.message = resp.data;
      }
    },
    editItem(item) {
      this.editedIndex = this.schnittstellen.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isEditModus = true;
    },
    deleteItem(item) {
      this.editedIndex = this.schnittstellen.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.schnittstellen.splice(this.editedIndex, 1);
      this.closeDelete();
      this.form_saved = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          Object.assign(this.schnittstellen[this.editedIndex], this.editedItem);
        } else {
          this.schnittstellen.push(this.editedItem);
        }
        this.close();
        this.message = "Änderungen wurden noch nicht gespeichert.";
        this.snackbar = true;
        this.form_saved = false;
      }
    },
    async saveSchnittstellen() {
      try {
        let resp = await SchnittstellenService.updateSchnittstellen(
          this.schnittstellen,
          this.$store.getters.currentSystemId
        );
        this.schnittstellen = resp.data;
        if (resp.status == 200) {
          this.message = "Änderungen wurden gespeichert.";
          this.form_saved = true;
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
      this.refreshCurrentSystem();
    },
     async refreshCurrentSystem() {
      let resp1 = await SystemService.getNewestVersionOfSystem(
        this.$store.getters.currentSystemId
      );
      var currSystem = resp1.data;
      this.$store.dispatch("registerSystem", currSystem);

      if (resp1.status == 200) {
        this.message = "Änderungen wurden gespeichert.";
        this.form_saved = true;
      } else {
        this.message = resp1.errorMessage;
      }
    },
    richtungText(richtungid) {
      if (richtungid) {
        return this.richtungen.find(function(item) {
          return item.id === richtungid;
        }).text;
      }
    },
    kommunikationsArtText(kommunikationsArtid) {
      if (kommunikationsArtid) {
        return this.arten.find(function(item) {
          return item.id === kommunikationsArtid;
        }).text;
      }
    },
    protokollText(protokoll) {
      if (protokoll) {
        return this.protokolle.find(function(item) {
          return item.id === protokoll.id;
        }).bezeichnung;
      }
    },

    datenFormatText(datenFormat) {
      if (datenFormat) {
        return this.datenFormate.find(function(item) {
          return item.id === datenFormat.id;
        }).bezeichnung;
      }
    },
    haeufigkeitText(haeufigkeitid) {
      if (haeufigkeitid) {
        return this.haeufigkeiten.find(function(item) {
          return item.id === haeufigkeitid;
        }).text;
      }
    },
    validate() {
      this.$refs.form_schnittstellen.validate();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.form_saved) {
      const answer = window.confirm(
        "Änderungen sind noch nicht gespeichert worden.\nSollen die Änderungen verworfen werden?"
      );
      if (answer) {
        this.form_saved = true;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
