import axios from "axios";

export default {
  getSchnittstellen(systemid) {
    return axios.get(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/schnittstellen/" +
        systemid.substring(1)
    );
  },
  updateSchnittstellen(schnittstellen, systemid) {
    return axios
      .post(
        process.env.VUE_APP_AIS_BASE_URL_OLD +
          "/schnittstellen/" +
          systemid.substring(1),
        schnittstellen
      )
      .catch(error => {
        throw error.response.data.errorMessage;
      });
  }
};
